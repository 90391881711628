import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  pools: []
};

export const poolinfoSlice = createSlice({
  name: "pooldatainfo",
  initialState,
  reducers: {
    addPoolInfo: (state, action) => {
      const { planNumber,Period,time, numberofInv, Totalreturn, Dayactive, avrageReturn, Minorder, Asm, Maxorder,Plandays } = action.payload;
      const existingPool = state.pools.find(
        (pool) => pool.planNumber === planNumber
      );
      if (existingPool) {
        // Update existing pool
        existingPool.Period = Period;
        existingPool.time = time;
        existingPool.numberofInv = numberofInv;
        existingPool.Totalreturn = Totalreturn;
        existingPool.Dayactive = Dayactive;
        existingPool.avrageReturn = avrageReturn;
        existingPool.Minorder = Minorder;
        existingPool.Asm = Asm;
        existingPool.Maxorder = Maxorder;
        existingPool.Plandays = Plandays;
      } else {
        // Add new pool
        state.pools.push({
          planNumber,
          Period,
          time,
          numberofInv,
          Totalreturn,
          Dayactive,
          avrageReturn,
          Minorder,
          Asm,
          Maxorder,
          Plandays
        });
      }
    },
  },
});

export const { addPoolInfo } = poolinfoSlice.actions;
export default poolinfoSlice.reducer;
